.ft-area > .family-site > .select-box > .go-btn {
    background-color:#888888;
    color:white;
    width:50px;
    height:33px;
    position:absolute;
    top:0;
    right:0;
    border:0;
    font-size:10px;
    font-weight:500;
    line-height:33px;
}
.ft-area {
    padding:20px 0 0 950px;
    width:100%;
    overflow:hidden;
    box-sizing:border-box;
  }
  
  .ft-area::after {
    content:"";
    display:block;
    clear:both;
  }
  
  .ft-area > .family-site {
    float:right;
    width:33.3333%;
  }
  
  .ft-area > .family-site > .select-box {
    position:relative;
    width:258px;
    height:33px;
  }
  
  .ft-area > .family-site > .select-box > .hide {
    display:block;
    position:absolute;
  }
  .select-box {
    position: relative;
    width: 200px;
    z-index: 1;
}

.select-box label {
    position:absolute;
    top:1px;
    left:5px;
    padding:0.8em 0.5em;
    color:#999999;
    z-index:-1;
}

.select-box select {
    width:100%;
    height:auto;
    line-height:normal;
    border: 1px solid transparent;
    margin:0;
    padding:0;
    color:#9999a8;
    font-weight:bold;
    cursor:pointer;
    padding-left:15px;
    background-color:#f6f6f6;
}

.select-box select > option {
    color:black;
    background-color:white;
}