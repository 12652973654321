.test_dropdown:hover .test_dropdown_data {
    display: block !important;
}
div {
    font-family: 'jalnan';
}
.context {
    margin-top :  3%;
    margin-left: 100px;
    margin-bottom :  3%;
    text-align: left;
    
}
.context_contact {
    margin-top :  3%;
    margin-left: 5%;
    margin-right: 10%;
    margin-bottom :  3%;
    text-align: left;
    
}
.context-slide {
    font-family: 'jalnan';
    text-decoration-line: none !important;
    text-decoration: none !important;
    color: white;
    font-weight: 700;
    font-size: 60px;
}

.link {
    text-decoration-line : none;
    text-decoration : none;
}

a {
    text-decoration-line: none !important;
    text-decoration: none !important;
}

.greetings {
    margin-top: 5%;
    margin-left: 5%;
    margin-bottom: 5%;
}
.greetings_contents {
    color: #000000;
}
.faq {
    margin-top: 5%;
    margin-left: 15%;
    margin-right: 20%;
    margin-bottom: 10%;
}
.contact_box {
    position: fixed;
    bottom: 0; left: 0;
    height: auto;
    width: 100%;
    background-color: #ddd;
    z-index: 100;
}
.comment {
    margin-top: 2%;
    /*border-bottom: 1px solid #bdbdbd;*/
    padding-bottom: 1%;
    margin-right: 10%;
    /*margin-bottom: 1%;*/
}
.comment_cloud {
    margin-top: 2%;
    margin-right: 10%;
}

.datacenter_img {
    margin-left : 0%;
    margin-top : 1%;
    margin-bottom : 3%;
    display:flex;
    align-items: center;
    justify-content: flex-start;
}
.new_img {
    margin-left : 0%;
    margin-top : 1%;
    margin-right: 10%;
}
.solid {
    margin-top: 2%;
    border-bottom: 1px solid #363232;
    margin-right: 10%;
    margin-bottom: 0.5%;
}
.solid_privacy {
    margin-top: 1%;
    margin-bottom: 1%;
    border-bottom: 1px solid #363232;
    margin-right: 10%;
}
.datacenter_img_pdu {
    margin-left : 0%;
    margin-top : 1%;
    margin-bottom : 3%;
    align-items: center;
    justify-content: flex-start;
}
.datacenter {
    margin-left : 0%;
    margin-top : 1%;
    margin-bottom : 3%;
    display: table;
    margin-right: auto;
}
.home_context {
    margin-top: 0%;
    margin-bottom: 5%;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-start;
}
.home_card_1 {
    margin-left: 50%;
    width: 28%;
    margin-right: 30%;
    height: 10%;
    
}
.home_card_1 > img {
    margin-top: 30%;
    width: 100%;
    margin-bottom: 3%;
    flex: 1;
}
.home_card_2 {
    display: table;
    margin-left: 20%;
    float: left;
    width: 28%;
    height: 25%;
}

.home_card_2 > img {
    margin-top: 30%;
    width: 100%;
    margin-bottom: 3%;
    flex: 2;
}

.cloud {
    margin-left : 0%;
    margin-top : 1%;
    margin-bottom : 3%;
    display:flex;
    align-items: center;
    justify-content: flex-start;
}
/*
.datacenter_img > a:link {
    margin-right: 1%;
    border: black 1px solid;
    text-decoration-line: none !important;
    text-decoration: none !important;
}

.datacenter_img > a:visited {
    margin-right: 1%;
    border: black 1px solid;
    text-decoration-line: none !important;
    text-decoration: none !important;
}

.datacenter_img > a:active {
    margin-right: 1%;
    border: black 1px solid;
    text-decoration-line: none !important;
    text-decoration: none !important;
}
*/
.context > .context_map {
    margin-left: 100px !important;
    align-content: left !important;
    margin-right: 3%;
    display:inline-table;
}
.black{
    color: black !important;
    font-size: 'jalnan';
}


.context > .map {
  width: 90%;
  height: 600px;
  align-items: center;
  align-content: right;
  justify-content: center;
  margin-left: 5%;
  margin-right: auto;
  border-style: solid;
  border-width: medium;
  border-color: #D8D8D8;
  display:inline-table;
}
.map_addr {
    font-family: 'jalnan';
    width: 180px;
    font-size: 13px;
    padding:6px 0;
    text-align: center;
}
.info{
    font-size:0;
    padding:29px 0 0 400px;
    display:inline-block;
}
.info > .info-01 {
    display:inline-block;
  }

.test {
    left: auto;
    right: 100%;
    margin-left: auto;
    margin-right: 1px;
}

.context > img {
    margin-top: 1%;
    margin-right: 3%;
}
.header {
    text-align: center;
  }