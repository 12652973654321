.contentsHeader {
    width: 100%;
    /*border: solid;*/
    padding : 120px 0;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
}

.contentsHeader > h1 {
    text-align : center;
    font-family: 'jalnan';
}