* {padding: 0; margin: 0; list-style: none; font-family: 'jalnan';}
  .history_body { 
    margin-left: 7%;
    margin-top: 2%;
    margin-bottom: 6%;
  }

  .logo img {
    margin-left: 0px !important; padding:0px !important;
  }
  .history h2 {position: relative; font-size: 33px; color: #000; line-height: 1;}
  .history h2:before {
    content:""; position: absolute;
    left:-48px; top:50%; transform: translateY(-50%);
    width: 17px; height: 17px; border-radius: 100%;
    background:#fff; border: 5px solid #0d3a70;
    box-sizing: border-box;}

  .history div {position: relative; padding: 0 0 0 48px;}
  .history div:before { content:""; position:absolute; left:6.6px; top:0;
          width: 4.7px; height:100%; background:#ddd;}
  .history div:first-child:before { top:10px; height:calc(100% - 10px);}


  .history ol { padding: 20px 0 74px; }
  .history li {font-size: 17px; color:#000; line-height: 29px;  }
history li {font-size: 17px; color:#000; line-height: 29px;  }
